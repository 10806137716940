import PropTypes from 'prop-types';
import { Card, CardContent, Fab, Grid, IconButton, Snackbar, Typography } from "@mui/material";
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';

const message = {
    incoming: 'Incoming call',
}

export default function SnackbarCallAlert({ phonenumber, type, onAnswer, onHangUp, isOpen }) {
    return (
        <Snackbar
        anchorOrigin={{
            vertical: 'top', 
            horizontal: 'right'
        }}
        sx={{ my: 2}}
        open={isOpen}
        autoHideDuration={6000}
        >
            <Card style={{ display: 'flex', padding: '8px 10px', m: 0, width: 350, height: 100}}>
                <CardContent style={{ flex: 1, mx: 0 }}>
                    <Typography variant="subtitle2" component="div">
                        {phonenumber}
                    </Typography>
                    <Typography variant="subtitle2" color="primary" sx={{ mt: 2 }}>
                        {message[type]}
                    </Typography>
                </CardContent>
               <Grid container
                direction="column"
                justifyContent="center"
                alignItems="flex-end"
                 spacing={1}
               >
                    <Grid item>
                        <Fab 
                        onClick={onAnswer}  
                        color='success'
                        sx={{
                            width: 30, // adjust the width as needed
                            height: 30, // adjust the height as needed
                            minHeight: 'unset', // reset the default minHeight
                          }} 
                        >
                            <CallIcon fontSize='small'/>
                        </Fab>
                    </Grid>
                    <Grid item>
                        <Fab 
                        onClick={onHangUp} 
                        color='error'
                        sx={{
                            width: 30, // adjust the width as needed
                            height: 30, // adjust the height as needed
                            minHeight: 'unset', // reset the default minHeight
                          }} 
                        >
                            <CallEndIcon fontSize='small'/>
                        </Fab>
                    </Grid>
                </Grid>
            </Card>
        </Snackbar>
    );
}

SnackbarCallAlert.propTypes = {
    phonenumber: PropTypes.string,
    type: PropTypes.string,
    onAnswer: PropTypes.func,
    onHangUp: PropTypes.func,
    isOpen: PropTypes.bool,
};

