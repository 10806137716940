import { Stack, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import Iconify from "src/components/iconify";

const PhoneNumberActions = () => {

    const theme = useTheme();

    return (
        <Stack spacing={2} flexGrow={1} sx={{ p: 0 }}>
        <Stack direction="row" alignItems="center">
          <Stack direction="column" alignItems="center" flexGrow={1}>
              <ToggleButtonGroup size="small" fullWidth sx={{ border: 'none' }}>
                  <ToggleButton value="list" sx={{ transition: '0.2s ease-in-out', '&:hover': { backgroundColor: 'transparent', color: `${theme.palette.primary.main} !important` } }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '15%',
                    }}>

                      <Iconify icon="bitcoin-icons:clock-filled" />
                      <Typography sx={{ fontSize: 10,
                        '&:hover': { backgroundColor: 'transparent', color: `${theme.palette.primary.main} !important` }
                       }} color="grey.600" >Recents</Typography>
                  </div>
                  </ToggleButton>

                  <ToggleButton value="list" sx={{ transition: '0.2s ease-in-out', '&:hover': { backgroundColor: 'transparent', color: `${theme.palette.primary.main} !important` } }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '15%'}}>
                      <Iconify icon="ion:person-circle" />
                      <Typography sx={{ fontSize: 10,
                        '&:hover': { backgroundColor: 'transparent', color: `${theme.palette.primary.main} !important` }}} color="grey.600" >Contacts</Typography>
                  </div>
                  </ToggleButton>

                  <ToggleButton value="list" sx={{ transition: '0.2s ease-in-out', '&:hover': { backgroundColor: 'transparent', color: `${theme.palette.primary.main} !important` } }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '15%'}}>
                      <Iconify icon="ion:people-circle-sharp" />
                      <Typography sx={{ fontSize: 10,
                        '&:hover': { backgroundColor: 'transparent', color: `${theme.palette.primary.main} !important` }}} color="grey.600" >Teams</Typography>
                  </div>
                  </ToggleButton>

                  <ToggleButton value="list" sx={{ transition: '0.2s ease-in-out', '&:hover': { backgroundColor: 'transparent', color: `${theme.palette.primary.main} !important` } }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '15%'}}>
                      <Iconify icon="eva:keypad-fill" sx={{ color: theme.palette.primary.main }} />
                      <Typography sx={{ fontSize: 10,
                        '&:hover': { backgroundColor: 'transparent', color: `${theme.palette.primary.main} !important` }}} color="primary" >Keypad</Typography>
                  </div>
                  </ToggleButton>

                  <ToggleButton value="grid" sx={{ transition: '0.2s ease-in-out', '&:hover': { backgroundColor: 'transparent', color: `${theme.palette.primary.main} !important` } }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '15%'}}>
                      <Iconify icon="carbon:voicemail" />
                      <Typography sx={{ fontSize: 10,
                        '&:hover': { backgroundColor: 'transparent', color: `${theme.palette.primary.main} !important` }}} color="grey.600" >Voicemail</Typography>
                  </div>
                  </ToggleButton>
              </ToggleButtonGroup>
          </Stack>
        </Stack>
      </Stack>
    );
}

export default PhoneNumberActions;
