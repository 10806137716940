/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react';

import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hook';

import { getLandingPage } from 'src/api/landingpages';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import LandingNewEditForm from '../landing-new-edit-form';

// ----------------------------------------------------------------------

export default function LandingEditView() {
  const settings = useSettingsContext();

  const params = useParams();

  const { id } = params;

  const [landingdata, setLandingdata] = useState({})

  useEffect(() => {
    const init = async () => {
      const result = await getLandingPage(id);

      if (result?.landingdata) {
        setLandingdata(result.landingdata)
      }

    }
    init()
  }, [])


  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={Object.keys(landingdata).length > 0 ? 'Edit Landing Page' : 'Create Landing Page'}
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },
          {
            name: 'Landing Pages',
            href: paths.dashboard.landing.list,
          },
          { name: Object.keys(landingdata).length > 0 ? landingdata?.page_name : 'New Landing' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <LandingNewEditForm currentPage={Object.keys(landingdata).length > 0 ? landingdata : null} id={id} />
    </Container>
  );
}
