import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Portal from '@mui/material/Portal';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useSelector } from 'react-redux';
// framer motion
import { AnimatePresence, m } from 'framer-motion';
// api
import { endpoints, postFetcher } from 'src/utils/axios';
// components
import Iconify from 'src/components/iconify';
import PhoneNumberKeypad from './phone-number-keypad';
import PhoneNumberActions from './phone-number-actions';
import PhoneNumberCalling from './phone-number-calling';
// twilio provider
import { useTwilio } from './twilio-provider';

// ----------------------------------------------------------------------

const ZINDEX = 98;

const POSITION = 24;

export default function PhoneNumberDial({ onCloseCompose }) {
  const smUp = useResponsive('up', 'sm');

  const { device, call, isCalling, callSids, direction, currentCall, phoneNumber, isHold,
    isMute, isRecording, setIsHold, setIsMute, setIsRecording, setPhoneNumber, makeCall, endCall } = useTwilio();

  const user = useSelector((state) => state.user);
  const userTwilioSid = user.user.twilio_sid;
  const fullScreen = useBoolean();

  const handleClickButton = (number) => {
    setPhoneNumber((prev) => prev + number);
  }

  const handleDelete = () => {
    setPhoneNumber((prev) => prev.slice(0, -1));
  };

  const callTo = async () => {
    await makeCall(phoneNumber)
  };

  const handleCallEnd = () => {
    endCall();
  }

  const handleHold = async () => {
    const callSid = callSids
    const { status, statusRecording } = await postFetcher(endpoints.twilio.conference.hold,
      {
        arg: {
          userTwilioSid,
          callSid,
          hold: !isHold,
          status: isRecording ? 'paused' : 'in-progress'
        }
      });

    if (status === 'success') {
      setIsHold(prev => !prev)
      setIsMute(false);
      setIsRecording(prev => !prev);
    };
  }

  const handleMute = async () => {
    const activeConnection = device._activeCall;
    if (activeConnection) {
      activeConnection.mute(!isMute);
      setIsMute(!isMute);
    }
  }

  const handleRecording = async () => {
    const callSid = callSids[0];
    const { status } = await postFetcher(endpoints.twilio.conference.recording.status,
      {
        arg: {
          userTwilioSid,
          conferenceSid: callSid,
          status: isRecording ? 'paused' : 'in-progress'
        }
      });

    if (status === 'success') setIsRecording(prev => !prev);
  }

  const handleAudioOutputChange = async (deviceId) => {
    try {
      await device.audio.speakerDevices.set(deviceId);
      console.log(`Audio output set to device: ${deviceId}`);
    } catch (error) {
      console.error('Failed to set audio output device:', error);
    }
  };

  return (
    <Portal>
      {(fullScreen.value || !smUp) && <Backdrop open sx={{ zIndex: ZINDEX }} />}

      <Paper
        sx={{
          right: 0,
          bottom: 0,
          borderRadius: 2,
          width: { xs: '100%', sm: 350 },
          height: { xs: '100%', sm: 600 },
          display: 'flex',
          position: 'fixed',
          zIndex: ZINDEX + 1,
          m: `${POSITION}px`,
          overflow: 'hidden',
          flexDirection: 'column',
          boxShadow: (theme) => theme.customShadows.dropdown,
        }}
      >
        <Stack
          direction="row"
          alignItems="end"
          sx={{
            bgcolor: 'background.neutral',
            p: (theme) => theme.spacing(0.5),
          }}
        >
          <Box sx={{ flexGrow: 1 }} />

          <IconButton onClick={onCloseCompose}>
            <Iconify icon="mingcute:close-line" />
          </IconButton>
        </Stack>

        <AnimatePresence>
          {
            !isCalling.value && (
              <m.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeOut", duration: 0.4 }} >
                <PhoneNumberKeypad
                  phoneNumber={phoneNumber}
                  handleClickButton={handleClickButton}
                  handleDelete={handleDelete}
                  callTo={callTo}
                />
                <PhoneNumberActions />
              </m.div>
            )
          }
        </AnimatePresence>

        <AnimatePresence>
          {
            isCalling.value && (
              <m.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeOut", duration: 0.4 }}
              >
                <PhoneNumberCalling
                  phoneNumber={phoneNumber}
                  callEnd={handleCallEnd}
                  hold={handleHold}
                  mute={handleMute}
                  recording={handleRecording}
                  isMute={isMute}
                  isHold={isHold}
                  isRecording={isRecording}
                  incomingCall={call}
                  onChangeAudio={handleAudioOutputChange}
                  isCallActive={currentCall}
                  direction={direction}
                />
              </m.div>
            )
          }
        </AnimatePresence>
      </Paper>
    </Portal>
  );
}

PhoneNumberDial.propTypes = {
  onCloseCompose: PropTypes.func,
};
