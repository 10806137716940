import React from 'react';
import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// Utils
import { fPhoneNumber } from 'src/utils/format-number';

// ----------------------------------------------------------------------

export default function ResultItem({ full_name, email, phone, address, highlights, onClickItem }) {
  const highlightWords = highlights.split(' ');
  // Highlight the matching text
  const getHighlightedText = (text, highlightWordss) => {
    // Create a regex with the words to highlight
    const regex = new RegExp(`(${highlightWordss.join('|')})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) =>
      highlightWords.includes(part.toLowerCase()) ? (
        <Box
          key={index}
          component="span"
          color="primary.main"
          sx={{
            typography: 'body2',
            fontWeight: 'fontWeightSemiBold',
          }}
        >
          {part}
        </Box>
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };

  return (
    <ListItemButton
      onClick={onClickItem}
      sx={{
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: 'transparent',
        padding: 0,
        borderBottomColor: (theme) => theme.palette.divider,
        '&:hover': {
          borderRadius: 1,
          borderColor: (theme) => theme.palette.primary.main,
          backgroundColor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
        },
      }}
    >
      <ListItem alignItems="flex-start" padding={0} >
        <ListItemText
          primaryTypographyProps={{
            typography: 'subtitle2',
            textAlign: 'left',
            padding: 0,
            margin: 0,
            sx: { textTransform: 'capitalize' },
          }}
          secondaryTypographyProps={{ typography: 'caption' }}
          primary={
            <Box
              component="span"
            >
              {full_name ? getHighlightedText(full_name, highlightWords) : ''}
            </Box>
          }
          secondary={
            <Grid>
              <Box
                component="span"
                sx={{
                  color: 'text.secondary',
                }}
              >
                {email ? getHighlightedText(email, highlightWords): ''}
              </Box>
            </Grid>
          }
        />
      </ListItem>
      <ListItem alignItems="flex-end">
        <ListItemText
          primaryTypographyProps={{
            typography: 'subtitle2',
            sx: { textTransform: 'capitalize' },
          }}
          secondaryTypographyProps={{ typography: 'caption' }}
          primary={
            <Box
              component="span"
              sx={{
                color: 'primary.main',
              }}
            >
              {phone ? getHighlightedText(fPhoneNumber(phone), highlightWords) : ''}
            </Box>
          }
          secondary={
            <Grid>
              <Box
                component="span"
                sx={{
                  color: 'text.secondary',
                }}
              >
                {address ? getHighlightedText(address, highlightWords): ''}
              </Box>
            </Grid>
          }
        />
      </ListItem>
    </ListItemButton>
  );
}

ResultItem.propTypes = {
  full_name: PropTypes.array,
  email: PropTypes.array,
  phone: PropTypes.array,
  address: PropTypes.array,
  highlights: PropTypes.string,
  onClickItem: PropTypes.func,
};
