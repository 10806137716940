import { useEffect } from 'react';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { CardActions, List, ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material';
// hooks
import { useNavigate, useParams } from 'react-router';
import { useResponsive } from 'src/hooks/use-responsive';
import { useBoolean } from 'src/hooks/use-boolean';
import { useSelector } from 'react-redux';
// api
import { useGetTwilioFetchAvailablePhoneNumbers, usePostReleasePhoneNumber } from 'src/api/twilio';
// routes
import { enqueueSnackbar } from 'notistack';
// _mock
import Iconify from 'src/components/iconify';
import { usePostStripeSubscriptionCancel } from 'src/api/stripe';

// ----------------------------------------------------------------------

export default function PhoneNumberActiveDetail() {

  const mdUp = useResponsive('up', 'md');

  const params = useParams();

  const navigate = useNavigate();

  const dialog = useBoolean();
  const user = useSelector((state) => state.user);
  const userTwilioSid = user.user.twilio_sid;

  const { availablephonenumbers } = useGetTwilioFetchAvailablePhoneNumbers({ userTwilioSid, sid: params.id });
  const { incomingPhoneNumbersTrigger, incomingPhoneNumbersLoading } = usePostReleasePhoneNumber();
  const { subscriptionTrigger, subscriptionLoading } = usePostStripeSubscriptionCancel();

  const handleReleasePhoneNumber = async () => {
    try {
      await incomingPhoneNumbersTrigger({ sid: params.id });
      await subscriptionTrigger({ subscription_id: 'sub_1PLJi4HKxvnTbiHizFGv0uZ4' });
      enqueueSnackbar('Phone Number Released', { variant: 'success' });
      navigate('/dashboard/contact_center/phone_numbers');
    } catch (error) {
      enqueueSnackbar('Failed To Delete Phone Number ', {
        variant: 'error',
      })
      console.error('error', error);
    }
  }

  const renderActions = (
    <>
      <Grid xs={12} md={7} sx={{ display: 'flex', alignItems: 'left' }}>

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          color='error'
          fullWidth
          loading={incomingPhoneNumbersLoading || subscriptionLoading}
          sx={{ ml: 2, minWidth: 150 }}
          onClick={handleReleasePhoneNumber}
        >
             <Typography variant="subtitle2">Release Phone Number</Typography>
        </LoadingButton>
      </Grid>
    </>
  );

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={5}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Details
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            You one-stop destination for everything related to your phone number.
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={7}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
                <Paper>
                  <Typography variant="subtitle1">
                    Phone Number
                  </Typography>

                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    { availablephonenumbers?.friendlyName || 'N/A'}
                  </Typography>
                  <Typography variant='body2' sx={{ color: 'text.disabled' }}>US</Typography>
                </Paper>
            </Stack>

            <Stack spacing={1.5}>
                <Paper>
                  <Typography variant="subtitle1">
                    Monthly Fee
                  </Typography>

                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    $1.15/month
                  </Typography>
                </Paper>
            </Stack>

            <Stack spacing={1.5}>
                <Paper>
                  <Typography variant="subtitle1">
                    Capabilities
                  </Typography>
                  <List>
                    <ListItem sx={{ pl: 0 }}>
                        <ListItemIcon>
                            <Iconify  
                            icon="lets-icons:check-fill"
                            width={24}
                            sx={{
                                color: (theme) => theme.palette.primary.main,
                            }}/>
                        </ListItemIcon>
                        <ListItemText primary='Voice' secondary="Receive incoming calls and make outgoing calls"/>
                    </ListItem>
                    <ListItem sx={{ pl: 0 }}>
                        <ListItemIcon>
                            <Iconify  
                            icon="lets-icons:check-fill"
                            width={24}
                            sx={{
                                color: (theme) => theme.palette.primary.main,
                            }}/>
                        </ListItemIcon>
                        <ListItemText primary='SMS' secondary="Send and receive text messages"/>
                    </ListItem>
                    <ListItem sx={{ pl: 0 }}>
                        <ListItemIcon>
                            <Iconify  
                            icon="lets-icons:check-fill"
                            width={24}
                            sx={{
                                color: (theme) => theme.palette.primary.main,
                            }}/>
                        </ListItemIcon>
                        <ListItemText primary='MMS' secondary="Send and receive multi media messages"/>
                    </ListItem>
                  </List>
                </Paper>
            </Stack>

            <Stack spacing={1.5}>
                <Paper>
                  <Typography variant="subtitle1">
                    A2P 10DLC Registration Required For US Messaging.
                  </Typography>

                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    To send SMS/MMS Messages To The US With This US Local Number, A Registration Process Is Required. This Process Can Be Performed After The Number Is Purchased 
                    <Typography component="a" variant='body2' href='#' target='_blank'> Learn More About A2P 10DLC Registration</Typography>
                  </Typography>
                </Paper>
            </Stack>
          </Stack>
          <CardActions>
            {renderActions}
          </CardActions>
        </Card>
      </Grid>
    </>
  );

  return (
    <Grid container spacing={3}>
        {renderDetails}
    </Grid>
  );
}