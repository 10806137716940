import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// framer motion
import { AnimatePresence, m } from 'framer-motion';
// components
import Iconify from 'src/components/iconify';
import { Fab, IconButton, useTheme } from '@mui/material';
import { useEffect } from 'react';

const PhoneNumberKeypad = ({
    phoneNumber,
    handleClickButton,
    handleDelete,
    callTo,
}) => {

    const theme = useTheme();
    const alphabet = {
        2: 'ABC',
        3: 'DEF',
        4: 'GHI',
        5: 'JKL',
        6: 'MNO',
        7: 'PQRS',
        8: 'TUV',
        9: 'WXYZ',
    }

    const handleKeyDown = (e) => {
        const { key } = e;
        console.log('key', key);
        if (key === 'Backspace') {
            handleDelete();
        } else if (key === 'Enter') {
            callTo();
        } else if (key >= 0 && key <= 9) {
            handleClickButton(key);
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    });

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: theme.spacing(10),
        }}>
            <Typography sx={{
                fontSize: '1.5rem',
                marginBottom: theme.spacing(phoneNumber ? 2 : 6.5),
            }}>
                {phoneNumber}
            </Typography>
            {/* IMASK Typography */}
            {/* <Typography
            sx={{
                fontSize: '1.5rem',
                marginBottom: (theme) => theme.spacing(phoneNumber ? 2 : 6.5),
                display: 'flex', // Asegura que el contenido se alinee correctamente
                alignItems: 'center' // Alinea verticalmente el contenido si es necesario
            }}
        >
            <IMaskInput
                mask="+1 (000) 000-0000"
                value={phoneNumber}
                readOnly
                unmask={false}
                style={{
                    textAlign: 'center',
                    fontSize: 'inherit', // Hereda el tamaño de fuente del Typography
                    border: 'none', // Opcional: elimina el borde si no lo necesitas
                    backgroundColor: 'transparent' // Opcional: ajusta el color de fondo
                }}
            />
        </Typography> */}
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: theme.spacing(1.5),
                marginBottom: theme.spacing(1.5),
            }}>
                {[...Array(9).keys()].map((i) => (

                    <Fab size='large' onKeyDown={handleKeyDown} onClick={() => handleClickButton(i + 1)} key={i + 1} color='gray.300'>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            position: 'absolute',
                            top: '15%'
                        }}>

                            <Typography >{i + 1}</Typography>
                            <Typography sx={{ fontSize: 8 }} color="grey.600" >{alphabet[i + 1]}</Typography>
                        </div>
                    </Fab>
                ))}
                <Fab size='large' color='gray.300'>
                    *
                </Fab>
                <Fab size='large' color='gray.300' onKeyDown={handleKeyDown} onClick={() => handleClickButton(0)}>
                    0
                </Fab>
                <Fab size='large' color='gray.300'>
                    #
                </Fab>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: theme.spacing(0),
            }}>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: theme.spacing(1),
                    marginBottom: theme.spacing(1),
                }}>
                    <Box />
                    <Fab size='large' onClick={callTo} color="primary" sx={{ mb: 0, mt: 1 }}>
                        <Iconify icon="solar:phone-bold" />
                    </Fab>
                    <AnimatePresence>
                        {
                            phoneNumber &&
                            <m.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: 20 }}
                                transition={{ duration: 0.1 }}
                            >
                                <IconButton onClick={handleDelete} sx={{ mb: 0, mt: 1 }}>
                                    <Iconify icon="fa6-solid:delete-left" />
                                </IconButton>
                            </m.div>
                        }
                    </AnimatePresence>
                </Box>
            </Box>
        </Box>
    )
}

PhoneNumberKeypad.propTypes = {
    phoneNumber: PropTypes.string,
    handleClickButton: PropTypes.func,
    handleDelete: PropTypes.func,
    callTo: PropTypes.func,
};

export default PhoneNumberKeypad;