// TwilioContext.js
import React, { createContext, useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { Device } from '@twilio/voice-sdk';
import { getTokens } from 'src/api/twilioconference';
import PropTypes from 'prop-types';
// api
import { usePostTwilioConference } from 'src/api/twilio';
import { useBoolean } from 'src/hooks/use-boolean';
// firebase
import { DATABASE } from 'src/firebase';
import { ref, remove } from "firebase/database";
// api
import { endpoints, postFetcher } from 'src/utils/axios';
import { useSelector } from 'react-redux';
// Crea el contexto
const TwilioContext = createContext();


// Hook personalizado para utilizar el contexto
export const useTwilio = () => useContext(TwilioContext);

// Provider del contexto
export const TwilioProvider = ({ children }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [device, setDevice] = useState(null);
    const [call, setCall] = useState(null);
    const [isReceivingCall, setIsReceivingCall] = useState(false);
    const { conferenceTrigger } = usePostTwilioConference();
    const [phoneNumberCalling, setPhoneNumberCalling] = useState('');
    const [isRecording, setIsRecording] = useState(true);
    const [isHold, setIsHold] = useState(false);
    const [isMute, setIsMute] = useState(false);
    const [incomingCall, setIncomingCall] = useState(null);
    const [callSids, setCallsids] = useState([]);
    const [currentCall, setCurrenCall] = useState('');
    const [direction, setDirection] = useState('');
    const onCall = useBoolean();
    const isCalling = useBoolean();
    const isCallIncoming = useBoolean(false);

    const user = useSelector((state) => state.user);
    const userName = user.user.id;
    const userTwilioSid = user.user.twilio_sid;
    const userTwilioPhone = user.user.twilio_phone;
    useEffect(() => {
        const fetchTokenAndSetupDevice = async () => {
            try {
                const token = await getTokens({ identity: userName, twilioSid: userTwilioSid });
                const deviceNew = new Device(token, {
                    codecPreferences: ['opus', 'pcmu'],
                });

                deviceNew.register();

                // Escuchar evento de desconexión
                deviceNew.on('disconnect', () => {
                    console.log('TWILIO PROVIDER: Call ended');
                    isCalling.onFalse();
                    onCall.onFalse();
                    setPhoneNumber('');
                    setPhoneNumberCalling('');
                    setDirection("");
                });

                // Otros eventos
                deviceNew.on('connect', () => {
                    console.log('TWILIO PROVIDER: connect El cliente ha contestado la llamada');
                });
                deviceNew.on('cancel', () => {
                    console.log('TWILIO PROVIDER: Call canceled');
                });

                deviceNew.on('ready', () => {
                    console.log('TWILIO PROVIDER: Twilio.Device Ready');
                    setDevice(deviceNew);
                });

                deviceNew.on('registered', () => {
                    console.log('TWILIO PROVIDER: The device is ready to receive incoming calls.');
                });

                deviceNew.on('offline', () => {
                    console.log('TWILIO PROVIDER: The device is offline.');
                });

                deviceNew.on('incoming', connection => {
                    console.log("TWILIO PROVIDER: INCOMING CALL")
                    setIsReceivingCall(true);
                    setCall(connection);
                    setPhoneNumberCalling(connection.parameters.From);
                    isCallIncoming.onTrue();
                    onCall.onTrue();
                    setIncomingCall(connection);
                    setCallsids([{ sid: connection.parameters.CallSid }])
                    connection.on('accept', async () => {
                        onCall.onTrue();
                        setDirection("inbound");
                        const { status, data } = await postFetcher(endpoints.twilio.conference.participants,
                            {
                                arg: {
                                    callSid: connection.parameters.CallSid,
                                    userTwilioSid
                                }
                            });
                        if (status === 'success') {
                            const prevCallSids = [];
                            data.forEach(participant => { prevCallSids.push({ sid: participant.callSid }) });
                            setCallsids(prevCallSids)
                        }
                    });
                    connection.on('disconnect', () => {
                        isCalling.onFalse();
                        setPhoneNumber('');
                        setPhoneNumberCalling('');
                        setIsHold(false);
                        setIsMute(false);
                        setDirection("");
                    });
                    connection.on('cancel', () => {
                        isCallIncoming.onFalse();
                        setIsReceivingCall(false);
                        setPhoneNumber('');
                        setPhoneNumberCalling('');
                        setDirection("");
                    });
                });

                setDevice(deviceNew);

                deviceNew.on('error', (error) => {
                    console.error(`Twilio.Device Error: ${error.message}`);
                });

            } catch (error) {
                console.error('Failed to fetch token:', error);
            }
        };
        if (user.isAuth) {
            fetchTokenAndSetupDevice();
        }

        // Limpieza: Desregistrar eventos y destruir el dispositivo
        return () => {
            if (device) {
                device.destroy();
            }
        };
        // eslint-disable-next-line
    }, []);

    // Memorizar funciones con useCallback para evitar que se recrean en cada render
    const acceptCall = useCallback(() => {
        if (call) {
            call.accept();
            setIsReceivingCall(false);
            isCallIncoming.onFalse();
            setPhoneNumber(call.parameters.From);
            isCalling.onTrue();
            setDirection("inbound");
        }
    }, [call, isCallIncoming, isCalling]);

    const rejectCall = useCallback(() => {
        if (call) {
            call.reject();
            setCall(null);
            setIsReceivingCall(false);
            incomingCall.reject();
            isCallIncoming.onFalse();
            setPhoneNumber('');
            setPhoneNumberCalling('');
        }
    }, [call, incomingCall, isCallIncoming]);

    const makeCall = useCallback(async (To) => {
        if (device) {
            const prevCallSids = []
            const response = await conferenceTrigger({ to: To, accountSid: userTwilioSid, from: userTwilioPhone });
            const outboundCallSid = { sid: response.data.callSid }
            setCurrenCall(outboundCallSid);
            prevCallSids.push(outboundCallSid)
            const outboundCall = await device.connect({ params: { To, callerId: userTwilioPhone } });
            setCall(outboundCall);
            isCalling.onTrue();
            onCall.onTrue();

            outboundCall.on('accept', connection => {
                const clientCallSid = { sid: connection.parameters.CallSid }
                prevCallSids.push(clientCallSid);
                setDirection("outbound");
            });

            outboundCall.on('disconnect', async connection => {
                isCalling.onFalse();
                setPhoneNumber('');
                setPhoneNumberCalling('');
                const userRef = ref(DATABASE, `twilioRealTimeEvents/${outboundCallSid.sid}`);
                await remove(userRef);
                setIsHold(false);
                setIsMute(false);
                setDirection("");
            });

            setCallsids(prevCallSids)
        }
    }, [device, conferenceTrigger, isCalling, onCall, userTwilioSid, userTwilioPhone]);

    const endCall = useCallback(() => {
        if (call) {
            call.disconnect();
            setCall(null);
            isCalling.onFalse();
            setPhoneNumber('');
            setPhoneNumberCalling('');
        }
    }, [call, isCalling]);

    // Envolver el valor del contexto en useMemo para evitar renders innecesarios
    const value = useMemo(
        () => ({
            device,
            call,
            isReceivingCall,
            isCalling,
            phoneNumberCalling,
            callSids,
            direction,
            onCall,
            currentCall,
            phoneNumber,
            isHold,
            isMute,
            isRecording,
            setIsHold,
            setIsMute,
            setIsRecording,
            setPhoneNumber,
            acceptCall,
            rejectCall,
            makeCall,
            endCall,
        }),
        [device, call, isReceivingCall, isCalling, phoneNumberCalling, callSids, direction, onCall, currentCall, phoneNumber, isHold,
            isMute, isRecording, setIsHold, setIsMute, setIsRecording, setPhoneNumber, acceptCall, rejectCall, makeCall, endCall]
    );

    return (
        <TwilioContext.Provider value={value}>
            {children}
        </TwilioContext.Provider>
    );
};

TwilioProvider.propTypes = {
    children: PropTypes.node,
};