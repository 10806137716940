'use client'

/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable operator-assignment */
/* eslint-disable one-var */
/* eslint-disable spaced-comment */
/* eslint-disable no-useless-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-else-return */
/* eslint-disable guard-for-in */
/* eslint-disable prefer-const */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
/* eslint-disable-next-line react/no-unescaped-entities */

// Forms
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useRef, useMemo, useState, useEffect, useCallback } from 'react'

// @mui
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Card,
  Grid,
  List,
  Chip,
  Stack,
  Button,
  Select,
  MenuItem,
  ListItem,
  TextField,
  Typography,
  InputLabel,
  FormControl,
  ListItemText, InputAdornment
} from '@mui/material'

import { paths } from 'src/routes/paths'
import { useRouter } from 'src/routes/hook'

import { useBoolean } from 'src/hooks/use-boolean'
import { useResponsive } from 'src/hooks/use-responsive'

import uuidv4 from 'src/utils/uuidv4'
import mime from 'mime'

import { useAuthContext } from 'src/auth/hooks'
// Data
import { object_types } from 'src/_mock/_crm-fields'
import { useListCatalogs } from 'src/api/catalogs/catalogs'
// APIS with SWR
import { useUploadFile, useCreateLandingPages, useUpdateLandingPages, removeFile } from 'src/api/landingpages/index'

import { useSnackbar } from 'src/components/snackbar'
import EmptyContent from 'src/components/empty-content'
import { RHFAutocompleteAction } from 'src/components/hook-form/rhf-autocomplete'
// Forms
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFUploadFile,
} from 'src/components/hook-form'

import { useSelector } from 'src/redux/store'
import LayoutForm from './shared/form'


// ----------------------------------------------------------------------


Math.hRandom = (positions, float = false) => {

  let number = ""
  let point = -1

  if (float) point = Math.floor(Math.random() * positions) + 1

  for (let i = 0; i < positions; i++) {
    if (i == point) number += "."
    number += Math.floor(Math.random() * 10)
  }

  return number

}

export default function LandingNewEditForm({ currentPage, id }) {

  console.log('CURRENT PAGE => ', currentPage)

  const { user } = useSelector(state => state.user)

  const { mutate: createLandingPages } = useCreateLandingPages()
  const { mutate: updateLandingPages } = useUpdateLandingPages()
  const { mutate: uploadFile } = useUploadFile()


  // Hooks
  const openDetails = useBoolean()
  const { enqueueSnackbar } = useSnackbar()

  const [tempPage, setTempPage] = useState({})
  const [usersLevels, setUsersLevels] = useState([])
  const [reset, setReset] = useState(false)
  const [errors, setErrors] = useState({})
  const [hide_options, setHideOptions] = useState(false)
  const [loadingLanding, setLoadingLanding] = useState(false)
  const [maxupload, setMaxUpload] = useState(false)
  const [questionslist, setQuestions] = useState([])
  const [pageExist, setPageExist] = useState(false)
  const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0, scrollLeft: 0 })
  const scrollDemoRef = useRef(null)
  const [isRemove, setIsRemove] = useState(false)
  const [isSuper, setIsSuper] = useState(false)


  const handleScroll = () => {

    if (scrollDemoRef.current) {
      const { scrollTop, scrollLeft } = scrollDemoRef.current
      setScrollPosition({ scrollTop, scrollLeft })
    }
  }


  const nameFile = (data) => {
    const extention = mime.getExtension(data.type)
    return `${uuidv4()}.${extention}`
  }

  const nameExtention = (data) => {
    const extention = mime.getExtension(data.type)
    console.log(extention)
    return extention
  }

  function niceBytes(x) {
    return Math.round(x / (1024 * 1024))
  }

  const handleDropSingleFile = useCallback(async (acceptedFiles) => {

    setMaxUpload(false)

    // enqueueSnackbar('Uploading Media', { variant: 'info', duration: 5000, })

    const newFile = acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file),
    }))[0]




    if (niceBytes(newFile.size) > 400) {
      setMaxUpload(true)
      enqueueSnackbar('Attachments Exceed 400 MB Limit.', { variant: 'error' })
      return
    }

    setTempPage((prevTempPage) => ({
      ...prevTempPage,
      hero_assets: newFile?.preview, // upload?.data?.link,
      hero_assets_file: newFile,
      hero_extention: nameExtention(newFile),
      hero_name: nameFile(newFile)
    }))


  }, [])


  const handleDropSingleFileRemove = useCallback((acceptedFiles) => {

    if (currentPage.created_by == 'Sales Rush') return

    setTempPage((prevTempPage) => ({
      ...prevTempPage,
      hero_assets: '', // upload?.data?.link,
      hero_assets_file: '',
      hero_extention: '',
      hero_name: ''
    }))

    setIsRemove(true)
  }, [currentPage])

  const router = useRouter()

  const SchemaLandingPage = Yup.object().shape({
    page_name: Yup.string().required('Page Name is required!'),
    url_path: Yup.string().required('Url Path is required!'),
    navigation_label: Yup.mixed().optional(),
    display_on_site: Yup.mixed().optional(),
    save_submission_to: Yup.string().required('Save Submission As is required!'),
    title: Yup.mixed().optional(),
    subtitle: Yup.mixed().optional(),
    hero_call_to_action: Yup.mixed().optional(),
    hero_assets: Yup.mixed().optional(),
    benefit: Yup.mixed().optional(),
    minimum_credit: Yup.mixed().optional(),
    past_foreclosure: Yup.mixed().optional(),
    features_call_to_action: Yup.mixed().optional(),
    form_setup: Yup.mixed().optional(),
    features: Yup.mixed().optional()
  })

  const defaultValues = useMemo(
    () => ({
      page_name: currentPage?.page_name || '',
      url_path: currentPage?.url_path || '',
      navigation_label: currentPage?.navigation_label || '',
      display_on_site: currentPage?.display_on_site || '',
      save_submission_to: currentPage?.save_submission_to || '',
      title: currentPage?.title || '',
      subtitle: currentPage?.subtitle || '',
      hero_call_to_action: currentPage?.hero_call_to_action || '',
      hero_assets: '',
      benefit: '',
      minimum_credit: '',
      past_foreclosure: '',
      features_call_to_action: currentPage?.hero_call_to_action || '',
      form_setup: [],
      features: []
    }),
    [currentPage],
  )

  const methods = useForm({
    resolver: yupResolver(SchemaLandingPage),
    defaultValues,
    mode: "onSubmit",
  })

  const mdUp = useResponsive('up', 'md')

  const { data: catalogs } = useListCatalogs()

  const {
    watch,
    setValue,
    handleSubmit: handleSubmitP,
    formState: { isSubmitting },
  } = methods


  useEffect(() => {
    if (Object.keys(currentPage || {}).length > 0) {

      const data = currentPage

      console.log(data.form_setup, 'form_setup')

      data.form_setup = data.form_setup?.map(item => ({ id: Math.hRandom(9), ...item }))

      setTempPage(data)
      for (let i in data) {
        setValue(i, data[i])
      }

      if (currentPage?.created_by === 'Sales Rush') {
        setIsSuper(true)
      }

    }

  }, [currentPage])


  const prepareSubmit = (data, type) => {
    if (type == 'form-landing') {
      onSubmit()
    }
  }


  const onSubmit = handleSubmitP(async (data, e) => {

    try {

      setPageExist(false)
      setLoadingLanding(true)

      console.log(tempPage)

      const dataToSend = {
        ...tempPage,
        created_by: user?.id
      }

      console.log('tempPage => ', tempPage)

      delete dataToSend?.hero_assets_file

      if (tempPage?.hero_assets != currentPage?.hero_assets) {

        if (tempPage?.hero_assets != '') {
          const formData = new FormData()
          formData.append('file', tempPage?.hero_assets_file)
          formData.append('reference', `landing_pages/user_landing_pages/${id}`)

          tempPage?.hero_assets_file ? enqueueSnackbar('Uploading Attachments', { variant: 'info' }) : null

          await removeFile({
            reference: `landing_pages/user_landing_pages/${id}`
          })

          const upload = await uploadFile(formData)

          console.log('UPLOAD => ', upload)

          dataToSend.hero_assets = tempPage?.hero_assets != '' ? upload?.data?.url : ''

        }

      }

      // }
      else {
        dataToSend.hero_assets = isRemove ? '' : dataToSend.hero_assets
      }

      console.log('tempPage => ', tempPage)

      if (tempPage?.hero_assets == '' && isRemove) {
        await removeFile({
          reference: `landing_pages/user_landing_pages/${id}`
        })
      }

      console.log('BODY => ', JSON.stringify(dataToSend))

      // // Create a new crm field
      const response = currentPage ? await updateLandingPages(dataToSend, id) : await createLandingPages(dataToSend)

      console.log('ID ?> ', response?.data?._id)

      if (response.status === 'success') {

        if (!id && tempPage?.hero_assets_file) {

          console.log('tempPage?.hero_assets != currentPage?.hero_assets', tempPage?.hero_assets != currentPage?.hero_assets)
          if (tempPage?.hero_assets != currentPage?.hero_assets) {


            enqueueSnackbar('Uploading Attachments', { variant: 'info' })


            const formData = new FormData()
            formData.append('file', tempPage?.hero_assets_file)
            formData.append('reference', `landing_pages/user_landing_pages/${response.data?._id}`)
            // formData.append('bucket', 'landing-pages')

            const upload = await uploadFile(formData)
            if (!upload?.success) {
              window.scrollTo(0, 0)
              setLoadingLanding(false)
              enqueueSnackbar('Uploading Attachments failed', { variant: 'error' })
              return
            }

            dataToSend.hero_assets = tempPage?.hero_assets != '' ? upload?.data?.url : ''
            if (tempPage?.hero_assets == '') {
              await removeFile({
                reference: `landing_pages/user_landing_pages/${id}`
              })
            }

          }

          await updateLandingPages(dataToSend, response.data?._id)

        }
        else {
          dataToSend.hero_assets = isRemove ? '' : dataToSend.hero_assets

        }

        setIsRemove(false)

        enqueueSnackbar(`${currentPage ? 'Update success!' : 'Create success!'}`)
        router.push(paths.dashboard.landing.list)

      } else {
        window.scrollTo(0, 0)
        setLoadingLanding(false)
        setPageExist(true)
        console.log('error')
        enqueueSnackbar(response?.message || 'Try again', { variant: 'error' })
      }
    } catch (error) {
      console.error(error)
      setLoadingLanding(false)

    }

  })


  const onSubmitt = (async (data) => {
    try {

      console.log(data)

      setLoadingLanding(true)

      let dataToSend = {
        ...tempPage,
        created_by: user?.id
      }

      delete dataToSend?.hero_assets_file


      const formData = new FormData()
      formData.append('file', tempPage?.hero_assets_file)
      formData.append('name', tempPage?.hero_name)
      formData.append('user_id', user?.id)

      if (tempPage?.hero_assets != currentPage?.hero_assets) {
        const upload = await uploadFile(formData)
        if (upload?.status != 'success') {
          setLoadingLanding(false)
          enqueueSnackbar('upload Hero Image failed', { variant: 'error' })
          return
        }

        dataToSend.hero_assets = upload?.data?.link
      }

      console.log('BODY => ', JSON.stringify(dataToSend))

      // // Create a new crm field
      const response = currentPage ? await updateLandingPages(dataToSend, id) : await createLandingPages(dataToSend)

      if (response.status === 'success') {

        enqueueSnackbar(`${currentPage ? 'Update success!' : 'Create success!'}`)
        router.push(paths.dashboard.landing.list)
      } else {
        setLoadingLanding(false)
        console.log('error')
        enqueueSnackbar(response.message || 'Try again', { variant: 'error' })
      }
    } catch (error) {
      console.error(error)
      // enqueueSnackbar(response.message, { variant: 'error' })
    }
  })

  const handleOnClose = () => {
    router.push(paths.dashboard.landing.list)
  }


  const handleChangeField = (e) => {


    setTempPage((prevTempPage) => ({
      ...prevTempPage,
      [e.target.name]: e.target.value,
    }))

    console.log((e.target.name == 'page_name' && !currentPage))
    if (e.target.name == 'page_name' && !currentPage) {
      console.log('here')
      setTempPage((prevTempPage) => ({
        ...prevTempPage,
        'url_path': e.target.value?.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase(),
      }))

      setValue('url_path', e.target.value?.toLowerCase())
    }

    // setValue(e.target.name, e.target.value, { shouldValidate: true })
    setValue(e.target.name, e.target.value)

  }


  const handleChangeFeatures = (event, value) => {

    console.log(value)
    console.log(tempPage?.features)

    value = value.filter(Boolean)


    let data_features = value.map(item => ({ name: item, benefit: '' }))

    for (let i in data_features) {
      const key = data_features[i]

      const exist = tempPage?.features?.find(item => item.name === key.name)
      if (exist && key != undefined) {
        data_features[i].name = exist.name
        data_features[i].benefit = exist.benefit
      }
    }

    setTempPage((prevTempPage) => ({
      ...prevTempPage,
      features: data_features
    }))

    setValue('features', data_features)

  }

  const handleChangeFeaturesItem = (event, index) => {

    console.log(index, event.target.value)

    const data = tempPage?.features
    data[index].benefit = event.target.value

    setTempPage((prevTempPage) => ({
      ...prevTempPage,
      features: data
    }))

    setValue('features', data)

  }

  const handleChangeFrom = (value) => {


    setTempPage((prevTempPage) => ({
      ...prevTempPage,
      form_setup: value
    }))

    setValue('form_setup', value)


  }


  const deleteFeatures = (index) => {
    let copyData = tempPage?.features

    delete copyData[index]
    const valueDta = copyData.filter(Boolean)

    setTempPage((prevTempPage) => ({
      ...prevTempPage,
      features: valueDta
    }))

    setValue('features', valueDta)

  }


  useEffect(() => {
    if (catalogs.users_levels) {
      setUsersLevels(catalogs.users_levels)
    }
  }, [catalogs])


  useEffect(() => {

  }, [])


  const renderDetailForm = (
    <>
      {mdUp && (
        <Grid item md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Details
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>

            {`Welcome! To begin setting up your new page, let's dive into the core foundation. This is where the magic starts.`}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} md={8}>
        <Card>
          <Grid container spacing={2} p={2}>
            <Grid item xs={12} md={6}>
              <RHFTextField
                disabled={isSuper}
                name="page_name"
                label="Page Name"
                value={tempPage?.page_name?.replace(/\s{2,}/g, ' ') || ''}
                onChange={handleChangeField}
                defaultValue={currentPage?.page_name?.replace(/\s{2,}/g, ' ') || ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFTextField
                disabled={currentPage}
                name="url_path"
                label="URL Path"
                value={
                  currentPage ?
                    tempPage?.url_path?.replace(/(?:\s*-\s*)+|\s{1,}/gm, '-').toLowerCase() || '' :
                    tempPage?.url_path?.replace(/[^a-zA-Z0-9 ]/g, '').replace(/(?:\s*-\s*)+|\s{1,}/gm, '-').toLowerCase() || ''
                }
                onChange={handleChangeField}
                defaultValue={
                  currentPage ?
                    currentPage?.url_path?.replace(/(?:\s*-\s*)+|\s{1,}/gm, '-').toLowerCase() || '' :
                    currentPage?.url_path?.replace(/[^a-zA-Z0-9 ]/g, '').replace(/(?:\s*-\s*)+|\s{1,}/gm, '-').toLowerCase() || ''
                }
                InputAdormet={{
                  endAdornment: <InputAdornment position="end">.salesrush.ai</InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="required">Display On Site Navigation</InputLabel>
                <Select
                  disabled={isSuper}
                  name="display_on_site"
                  label="Display On Site Navigation"
                  value={tempPage?.display_on_site || false}
                  onChange={handleChangeField}
                  defaultValue={currentPage?.display_on_site || false}
                  fullWidth
                >
                  <MenuItem value>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>


            </Grid>
            {
              tempPage?.display_on_site && (<Grid item xs={12} md={6}>
                <RHFTextField
                  disabled={isSuper}
                  name="navigation_label"
                  label="Navigation Label"
                  value={tempPage?.navigation_label || ''}
                  defaultValue={currentPage?.navigation_label || ''}
                  onChange={handleChangeField}
                />

              </Grid>)
            }


            <Grid item xs={12} md={6}>

              <RHFSelect
                disabled={isSuper}
                name="save_submission_to"
                label="Save Submission As"
                value={tempPage?.save_submission_to || ''}
                onChange={handleChangeField}
                defaultValue={currentPage?.save_submission_to || ''}

              >


                {object_types.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </RHFSelect>


            </Grid>


          </Grid>
        </Card>
      </Grid>
    </>
  )

  const renderHeroSection = (
    <>
      {mdUp && (
        <Grid item md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Hero Section
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            The hero section serves as the first impression for visitors, capturing their and conveying the essense of your message.
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} md={8}>
        <Card>
          <Grid container spacing={2} p={2}>
            <Grid item xs={12} md={12}>
              <RHFTextField
                disabled={isSuper}
                name="title"
                label="Title"
                multiline
                value={tempPage?.title || ''}
                onChange={handleChangeField}
                defaultValue={currentPage?.title || ''}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <RHFTextField
                disabled={isSuper}
                name="subtitle"
                label="Sub Title"
                multiline
                value={tempPage?.subtitle || ''}
                onChange={handleChangeField}
                defaultValue={currentPage?.subtitle || ''}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <RHFTextField
                disabled={isSuper}
                name="hero_call_to_action"
                label="Call To Action"
                multiline
                value={tempPage?.hero_call_to_action || ''}
                onChange={handleChangeField}
                defaultValue={currentPage?.hero_call_to_action || ''}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              {/* <RHFTextField
                name="hero_assets"
                label="Hero Image"
                value={tempPage?.hero_assets || ''}
                onChange={handleChangeField}
                defaultValue={currentPage?.hero_assets || ''}

              />

              <br /> */}

              <Box sx={{ gap: 3, display: 'flex', flexDirection: 'column' }}>

                <Typography variant="h6" sx={{ ml: 0.5, mb: 0.1 }}>
                  Hero Image
                </Typography>

                <RHFUploadFile
                  disabled={isSuper}
                  name="hero_assets"
                  url={!!currentPage}
                  extention={tempPage?.hero_extention || 'png'}
                  label="Hero Image"
                  file={tempPage?.hero_assets_file || tempPage?.hero_assets || ''}
                  onDrop={handleDropSingleFile}
                  onDelete={handleDropSingleFileRemove}
                  helperText={
                    maxupload ? <Typography
                      variant="caption"
                      sx={{
                        mt: 3,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'left',
                        color: 'red',
                      }}
                    >
                      Attachments Exceed 400 MB Limit
                    </Typography>
                      : <Typography
                        variant="caption"
                        sx={{
                          mt: 3,
                          mx: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.disabled',
                        }}
                      >
                        Allowed *.jpeg, *.jpg, *.png, *.mp4, *.mov
                      </Typography>
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  )

  const renderFeatureBenefics = (
    <>
      {mdUp && (
        <Grid item md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Feature And Benefits
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {`A feature and benefit section on a web page outlines what the product/service offers (features) and how it positively impacts the user (benefits). It's designed to persuade visitors by clearly demonstrating the value proposition.`}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} md={8}>
        <Card>
          <Grid container spacing={2} p={2} key="index-1-1">
            <Grid item xs={12} md={12}>
              {/* <RHFMultiSelect
                chip
                checkbox
                name="features"
                label="Features"
                options={[]}
                fullWidth
              /> */}

              <RHFAutocompleteAction
                disabled={isSuper}
                name="features"
                label="Features"
                helperText=""
                placeholder=""
                fullWidth
                multiple
                multiline
                freeSolo
                onChange={handleChangeFeatures}
                getOptionLabel={(option) => option.name}
                options={tempPage?.features?.length > 0 ? tempPage?.features.map((option) => option.name) : []}
                value={tempPage?.features?.length > 0 ? tempPage?.features.map((option) => option.name || option) : []}
                renderInput={(params) => (
                  <TextField {...params} label="Features" placeholder="Features" />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
                renderTags={(selected, getTagProps) =>
                  selected.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={option}
                      label={option}
                      size="small"
                      onDelete={() => deleteFeatures(index)}

                    />
                  ))
                }
              />

            </Grid>

            {tempPage?.features != undefined
              ? tempPage?.features.map((item, index) => (
                <Grid item xs={12} md={12}>
                  <RHFTextField
                    disabled={isSuper}
                    key={index}
                    name="benefit"
                    multiline
                    label={`${tempPage?.features[index]?.name || ''} (Describe Benefit)`}
                    value={tempPage?.features[index]?.benefit || ''}
                    defaultValue={currentPage?.features[index]?.benefit || ''}
                    onChange={(event) => handleChangeFeaturesItem(event, index)}
                    error={false}

                  />
                </Grid>
              )) : <Grid container spacing={2} p={2}>
                <Grid item xs={12} md={12}>
                  <EmptyContent
                    filled
                    title="No Data"
                    sx={{
                      py: 10,
                    }}
                  />
                </Grid>
              </Grid>}
            <Grid item xs={12} md={12}>
              <RHFTextField
                disabled={isSuper}
                id="features_call_to_action"
                name="features_call_to_action"
                label="Call To Action"
                multiline
                value={tempPage?.features_call_to_action || ''}
                defaultValue={tempPage?.features_call_to_action || ''}
                onChange={handleChangeField}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid >
    </>
  )

  const listDescriptionSetup = [
    {
      id: 1,
      text: 'Add an existing question or create a new one.'
    },
    {
      id: 2,
      text: 'Click on a question and drag it to the desired position on the form.'
    },
    {
      id: 3,
      text: `Once you're satisfied with the arrangement, click the preview button to see how your form will look to users.`
    }
  ]


  const renderFormSetup = (
    <>
      {mdUp && (
        <Grid item md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Form Setup
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Empower Your Form Experience: Customize, Arrange, and Personalize!
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {listDescriptionSetup.map((item) => (
                <ListItem
                  key={item.id}
                  disableGutters
                >
                  <ListItemText primary={`${item.id}. ${item.text}`} />
                </ListItem>
              ))}
            </List>
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} md={8}>

        <Card>
          <Grid container spacing={2} p={2}>

            <Grid item xs={12} md={12}>
              <LayoutForm
                isSuper={isSuper}
                section={tempPage?.save_submission_to}
                user_level={user?.user_level}
                onChange={(e) => handleChangeFrom(e)}
                saveChange={hide_options}
                data={tempPage?.form_setup?.sort((a, b) => a.order - b.order) || []}
                landing_page_id={id}
              />
            </Grid>

          </Grid>

        </Card>

      </Grid>

    </>
  )

  return (
    <Grid style={{ scrollBehavior: 'smooth' }}>
      <FormProvider methods={methods}>

        <Grid container spacing={3} sx={{ paddingBottom: 2 }}>
          {renderDetailForm}
        </Grid>

        <Grid container spacing={3} sx={{ paddingBottom: 2 }}>
          {renderHeroSection}
        </Grid>

        <Grid container spacing={3} sx={{ paddingBottom: 2 }}>
          {renderFeatureBenefics}
        </Grid>

        <Grid container spacing={3} sx={{ paddingBottom: 2 }}>
          {renderFormSetup}
        </Grid>

        <Stack spacing={3} sx={{ p: 2 }}>
          <Box width={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {
              !loadingLanding && (<Button
                color="inherit"
                variant="outlined"
                onClick={handleOnClose}
              >
                Cancel
              </Button>)
            }

            <LoadingButton
              disabled={isSuper}
              type="button"
              variant="contained"
              loading={loadingLanding}
              sx={{ ml: 2 }}
              onClick={(data) => prepareSubmit(data, 'form-landing')}
            >
              {currentPage ? 'Update Landing Page' : 'Create Landing Page'}
            </LoadingButton>
          </Box>
        </Stack>
      </FormProvider>
    </Grid>
  )
}


LandingNewEditForm.propTypes = {
  currentPage: PropTypes.object,
  id: PropTypes.string,
}