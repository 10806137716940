import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {

  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

export const postFetcher = async (url, { arg }) => {
  try {
    const response = await axiosInstance.post(url, arg);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  user: {
    details: '/auth/login',
    read: '/auth/',
    detail: '/user',
    phonenumber_subscription: {
      create: '/api/user/phonenumber/subscription/create',
      update: '/api/user/phonenumber/subscription/update',
      cancel: '/api/user/phonenumber/subscription/cancel',
    },
  },
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  otp: {
    send: '/api/otp/send',
    verify: '/api/otp/verify',
  },
  stripe: {
    subscription: {
      create: '/api/stripe/subscription/create',
      update: '/api/stripe/subscription/update',
      cancel: '/api/stripe/subscription/cancel',
    },
    payment_method: {
      create: '/api/stripe/paymentmethod/create',
      update: '/api/stripe/paymentmethod/update',
      list: '/api/stripe/paymentmethod/list',
    },
    customer: {
      create: '/api/stripe/customer/create',
      update: '/api/stripe/customer/update',
    },
  },
  twilio: {
    token: '/api/twilio/token',
    prices: '/api/twilio/pricing',
    available_phone_numbers: '/api/twilio/avaliablephonenumber',
    fetch_incoming_phone_numbers: '/api/twilio/fetch/incomingphonenumber',
    calls: {
      log: '/api/twilio/calls/log',
    },
    conference: {
      create: '/api/twilio/conference/create',
      hold: '/api/twilio/conference/holdcall',
      fetch: '/api/twilio/conference/fetch',
      end: '/api/twilio/conference/end',
      sid: (friendlyname) => `/api/twilio/conference/${friendlyname}/inprogress`,
      mute: '/api/twilio/conference/mute',
      participants: '/api/twilio/conference/get-participants',
      recording: {
        status: '/api/twilio/conference/recording/status',
      }
    },
    inconming_phone_number: {
      buy: '/api/twilio/buy/incomingphonenumber',
      active: '/api/twilio/active/incomingphonenumber',
      delete: '/api/twilio/delete/incomingphonenumber'
    },
    a2p: {
      customerProfile: {
        create: '/api/twilio/a2p/customerprofile/create',
        update: '/api/twilio/a2p/customerprofile/update',
        list: '/api/twilio/a2p/customerprofile/list',
        evaluate: '/api/twilio/a2p/customerprofile/evaluate',
        review: '/api/twilio/a2p/customerprofile/submitReview',
        attach: '/api/twilio/a2p/customerProfile/attach'
      },
      endUser: {
        create: '/api/twilio/a2p/endUser/bussinessInformation/create',
        attach: '/api/twilio/a2p/endUser/bussinessInformation/attach',
        addressCreate: '/api/twilio/a2p/endUser/address/create',
        authorizedCreate: '/api/twilio/a2p/endUser/authorizedRepresentative/create',
      },
      supportingDocument: {
        create: '/api/twilio/a2p/endUser/supportingDocument/create',
        attach: '/api/twilio/a2p/endUser/supportingDocument/attach',
      },
    }
  },
  landing: {
    list: '/api/user/landing-pages/list',
    create: '/api/user/landing-pages/create',
    update: '/api/user/landing-pages/update',
    view: '/api/user/landing-pages/view',
    delete: '/api/user/landing-pages/delete',
    activated: '/api/user/landing-pages/activated',
    upload: '/upload/single',
    questions: '/api/user/landing-pages/questions',
    questionAddOrUpdate: '/api/user/landing-pages/questions/addOrUpdate',
    deleteQuestions: '/api/user/landing-pages/delete-question',
  },
  catalogs: {
    list: '/api/catalogs',
  },
  crm: {
    fields: {
      root: '/api/system_fields',
      list: '/api/system_fields/all',
    },
    layouts: {
      root: '/api/system_layouts',
      list: '/api/system_layouts/all',
    },
  },
};
