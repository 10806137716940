export const object_types = [
  { value: 'contact', label: 'Contact' },
  { value: 'lead', label: 'Lead' },
  { value: 'opportunity', label: 'Opportunity' },
];

export const field_types = [
  { value: 'text', label: 'Text field' },
  { value: 'email', label: 'Email Field' },
  { value: 'phone', label: 'Phone Number' },
  { value: 'select_single', label: 'Single Select' },
  { value: 'date_picker', label: 'Date Picker' },
  { value: 'number', label: 'Number' },
  { value: 'select_multiple', label: 'Multiple Select' },
  { value: 'address', label: 'Address' },
  { value: 'city', label: 'City' },
  { value: 'region', label: 'State' },
  { value: 'postcode', label: 'Zip Code' },
];

export const field_formats = [
  { value: 'formatted', label: 'Formatted number' },
  { value: 'unformulated', label: 'Unformulated number' },
  { value: 'percentage', label: 'Percentage number' },
  { value: 'currency', label: 'Currency' },
];

export const field_sort = [
  { value: 'asc', label: 'Alphabetical' },
  { value: 'custom', label: 'Custom' },
];


export const field_defined = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];
