import PropTypes from 'prop-types';
// @mui
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import createData from 'src/sections/_examples/mui/table-view/utils';
import moment from 'moment';
import { fTimeDifference } from 'src/utils/format-time';
//


// ----------------------------------------------------------------------

const TABLE_DATA = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
  createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
  createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
  createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
];

export default function CallCollapsibleLog({ row }) {
  return (
    <TableContainer sx={{ mt: 3, overflow: 'unset' }}>
      <Scrollbar>
        <Table sx={{ minWidth: 800 }}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Direction</TableCell>
              <TableCell align="right">From</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Time</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
              <CollapsibleTableRow row={row} />
          </TableBody>
        </Table>
      </Scrollbar>
    </TableContainer>
  );
}

CallCollapsibleLog.propTypes = {
    row: PropTypes.object,
  };

// ----------------------------------------------------------------------

function CollapsibleTableRow({ row }) {
  const collapsible = useBoolean();

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            size="small"
            color={collapsible.value ? 'inherit' : 'default'}
            onClick={collapsible.onToggle}
          >
            <Iconify
              icon={collapsible.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
            />
          </IconButton>
        </TableCell>

        <TableCell component="th" scope="row">
            {row.direction}
        </TableCell>

        <TableCell align="right">{row.from}</TableCell>

        <TableCell align="right">{moment(row.dateCreated).format('LL')}</TableCell>

        <TableCell align="right">{moment(row.dateCreated).format('h:mm:ss a')}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell sx={{ py: 0 }} colSpan={6}>
          <Collapse in={collapsible.value} unmountOnExit>
            <Paper
              variant="outlined"
              sx={{
                py: 2,
                borderRadius: 1.5,
                ...(collapsible.value && {
                  boxShadow: (theme) => theme.customShadows.z20,
                }),
              }}
            >
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>To</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell align="right">Start Time</TableCell>
                    <TableCell align="right">End Time</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                       {row.to} 
                      </TableCell>
                      <TableCell>{fTimeDifference(row.startTime, row.endTime)}</TableCell>
                      <TableCell align="right">{moment(row.startTime).format('h:mm:ss a')}</TableCell>
                      <TableCell align="right">
                        {moment(row.endTime).format('h:mm:ss a')}
                      </TableCell>
                    </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

CollapsibleTableRow.propTypes = {
  row: PropTypes.object,
};
