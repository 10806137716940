/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable radix */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/named */
/* eslint-disable no-useless-return */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable guard-for-in */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */


import PropTypes from 'prop-types'
import React, { useRef, useState, useEffect, useCallback } from 'react'
// Third
import { Droppable, Draggable, DragDropContext } from '@hello-pangea/dnd'

import { useTheme } from '@mui/material/styles'
// Mui
import {
  Box,
  Chip,
  Paper,
  Stack,
  Button,
  TextField,
  Typography,
  FormControl,
} from '@mui/material'

// Hooks
import { useBoolean } from 'src/hooks/use-boolean'

// Theme
import { bgBlur } from 'src/theme/css'
// Constants



import {

  field_types,

} from 'src/_mock/_crm-fields'
// Apis
import { useReadAllCRMFieldsByUser } from 'src/api/user_fields'
import {
  useListCRMSystemLayoutsSectionLevel,
} from 'src/api/crm/layouts'
import { questionsList, removeQuestion, addOrUpdateQuestion } from 'src/api/landingpages'

import Iconify from 'src/components/iconify'
// Components
import { useSnackbar } from 'src/components/snackbar'
import EmptyContent from 'src/components/empty-content'
import { RHFAutocompleteAction } from 'src/components/hook-form/rhf-autocomplete'

import { useSelector } from 'src/redux/store'
import SvgColor from 'src/components/svg-color'
import KanbanDetails from './kanban/kanban-details'





Math.hRandom = (positions, float = false) => {

  let number = ""
  let point = -1

  if (float) point = Math.floor(Math.random() * positions) + 1

  for (let i = 0; i < positions; i++) {
    if (i == point) number += ".";
    number += Math.floor(Math.random() * 10)
  }

  return number

}


export default function LayoutForm({ section, user_level, onChange, saveChange = false, data, landing_page_id, isSuper }) {



  const { user } = useSelector((state) => state.user)

  const theme = useTheme()
  // Apis
  const { data: options } = useReadAllCRMFieldsByUser(
    user.id,
    user_level
  )

  console.log('ption => ', data)

  const { data: layout } = useListCRMSystemLayoutsSectionLevel(
    section,
    user_level,
  )
  // Refs
  const selectedID = useRef()
  // States
  const [fieldOpen, setFieldOpen] = useState(false)
  const [fields, setFields] = useState([])
  const [selectedFields, setSelectedFields] = useState(data)
  const [selectedFieldsReset, setSelectedFieldsReset] = useState([])
  const selectedFieldsRef = useRef(selectedFields)
  const [deleted, setDeleted] = useState(false)
  const [questions, setQuestions] = useState([])
  const [fieldOptions, setFieldOptions] = useState([])

  const icon = (name) => (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  )

  // Hooks
  const openDetails = useBoolean()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()


  const resultQuestions = async () => {
    const data = await questionsList(user?.id)

    if (data.status === 200) {
      const value = data?.data?.data?.result

      let data_options = []
      for (let i in value) {
        const key = value[i]

        console.log(key.response.users_levels.includes(user_level), key.response.users_levels, user_level)
        if (key.response.users_levels.includes(user_level)) {
          data_options.push(key)
        }
      }

      setQuestions(data_options)
    }
  }


  useEffect(() => {

    if (selectedFields.length === 0 && !deleted) {
      setSelectedFields(data)
    }

    resultQuestions()
  }, [selectedFields])
  // Functions
  const handleChangeFields = (event, newValue) => {


    if (newValue.length === 0) {
      setSelectedFields([])
      onChange([])
      setFields([])
    }


    for (let i in newValue) {
      const question = newValue[i]


      const exist_field = selectedFields.find(item => item.question === question)


      if (exist_field) {


        let copyFields = selectedFields

        let newField = []
        for (let i in copyFields) {
          const key = copyFields[i]
          if (key.question === question) {
            newField.push(key)
          }
        }

        setSelectedFields(newField)
        onChange(newField)
        setFields(newField)
        // onChange(newField)
        // setFields(newField)
      }
      // else if (selectedFields.filter(item => item.question === question)) {
      //   console.log('duplicate')
      // }
      else {

        let select = questions.find(item => item.question === question)

        let copyFields = selectedFields.concat(select)

        for (let i in copyFields) {
          copyFields[i].order = parseInt(i) + 1
        }


        setSelectedFields(copyFields)
        onChange(copyFields)
        setFields(copyFields)
      }
    }


    // if (newField.length > 0) {

    //   onChange(newField)
    //   setFields(newField)
    // }

    // console.log(event.target.value, newValue)

    // const itemValue = newValue[newValue.length - 1]

    // console.log('desc => ', itemValue)

    // const select = questions.find(item => item.question === itemValue)
    // let exist = selectedFields.find(item => item.question === select.question)

    // if (!exist) {
    //   console.log(select)
    //   setSelectedFields(prevState => ([
    //     ...prevState,
    //     select
    //   ]))
    // }

    // else {
    //   let copyFields = selectedFields

    //   let newField = []
    //   for (let i in copyFields) {
    //     const key = copyFields[i]
    //     if (key.question !== newValue[0]) {
    //       newField.push(key)
    //     }
    //   }

    //   console.log(newField)

    //   setSelectedFields(newField)
    //   onChange(newField)
    //   setFields(newField)
    // }



  }

  // Drag and Drop
  const onDragEnd = useCallback(async (result, fields) => {

    // console.log(result)


    try {
      if (!result.destination) return

      const copyFields = [...fields]

      const [reorderField] = copyFields.splice(result.source.index, 1)

      copyFields.splice(result.destination.index, 0, reorderField)

      copyFields.forEach((field, index) => {
        console.log(field.order, ' => order')
        field.order = index + 1
      })

      console.log(copyFields)

      setSelectedFields(copyFields)
      onChange(copyFields)

      // let data = copyFields.splice(result.destination.index, 0, reorderField)

      // console.log(data)

      // copyFields.forEach((field, index) => {
      //   field.field_order = index + 1
      // })

      // setSelectedFields(prevState => ({
      //   ...prevState,
      //   copyFields
      // }))
      // onChange(copyFields)
      // console.log('copyFields => ', copyFields)

      // for (let i in copyFields) {
      //   console.log(copyFields[i])
      //   copyFields[i].order = 0
      //   copyFields[i].order = Number(i) + 1
      // }

      // setSelectedFields(copyFields)
      // onChange(copyFields)
      // selectedFieldsRef.current = copyFields

    }
    catch (err) {
      console.log(err)
    }
    // try {
    //   if (!result.destination) return
    //   // Copy the selectedFields
    //   const copySelectedFields = selectedFields
    //   // Get the field that we need to reorder
    //   const [reorderField] = copySelectedFields.splice(result.source.index, 1)
    //   // Insert the field in the new position
    //   copySelectedFields.splice(result.destination.index, 0, reorderField)
    //   // Set the new order
    //   copySelectedFields.forEach((field, index) => {
    //     field.order = index + 1
    //   })

    //   console.log(copySelectedFields)
    //   // Set the new selectedFields
    //   setSelectedFields(copySelectedFields)
    //   onChange(copySelectedFields)
    //   selectedFieldsRef.current = copySelectedFields
    //   // Launch snackbar
    //   // launchSnackbar()
    // } catch (error) {
    //   console.log(error)
    // }
  },
    [fields],
  )

  const onSaveSubmit = async (newValues) => {

    if (Object.keys(newValues).length === 0) {
      return
    }

    // We need to create a copy of selectedFields
    const copyFields = [...selectedFields]
    // Validate that the event.target.value is an array or not
    const valuesArray = Array.isArray(newValues)
      ? newValues
      : [newValues]


    let apply = false

    console.log('copyFields => ', copyFields)
    console.log('valuesArray => ', valuesArray)
    console.log('newValues => ', newValues)

    for (const i in copyFields) {
      if (copyFields[i].order_id == newValues.order_id) {
        copyFields[i] = newValues
        apply = true
      }
    }

    if (!apply) {


      const newField = {
        order_id: newValues?.order_id || Math.hRandom(10),
        order: copyFields.length + 1,
        question: newValues?.question,
        response: newValues?.response,
        required: newValues?.required,
        more: newValues?.more,
        user_id: user?.id
      }

      console.log(newField, ' => newField')

      // We need to add the newField to the copyFields
      copyFields.push(newField)
      // Add the new fields
      setSelectedFields(copyFields)
      onChange(copyFields)
      selectedFieldsRef.current = copyFields

      setFields(valuesArray)

      const addOrUpdate = await addOrUpdateQuestion({
        ...newField, ...{
          landing_page_id,
          type: 'add',
          user_id: user?.id
        },
      })
    }
    else {

      // Set the new selectedFields
      setSelectedFields(copyFields)
      onChange(copyFields)
      selectedFieldsRef.current = copyFields

      const addOrUpdate = await addOrUpdateQuestion({
        ...newValues, ...{
          landing_page_id,
          type: 'edit'
        },
      })
    }



    openDetails.onFalse()
    await resultQuestions()

  }


  const deleteQuestion = (index) => {

    setDeleted(true)

    setSelectedFields([])
    onChange([])

    // We need to create a copy of selectedFields
    const copyFields = selectedFields

    let valueDta = []

    for (let i in copyFields) {
      const key = copyFields[i]
      if (key && i != index) {
        valueDta.push(key)
      }
    }

    setSelectedFields(valueDta)
    onChange(valueDta)

    // setDeleted(false)


    // setFields(valueDta)

  }

  useEffect(() => {
    if (saveChange) {
      setFieldOpen(false)
    }
  }, [saveChange])


  // Set default values
  useEffect(() => {

    if (layout && layout.fields) {
      if (layout.fields.length > 0) {
        // We need to get all ids from the layout fields and setFields array
        const fieldsIds = layout.fields.map((field) => field.id)
        setFields(fieldsIds)
        // Set default selectedFields
        setSelectedFields(layout.fields)
        setSelectedFieldsReset(layout.fields)
        selectedFieldsRef.current = layout.fields
      } else {
        // The layout has no fields
        setFields([])
        setSelectedFields([])
        setSelectedFieldsReset([])
        selectedFieldsRef.current = []
      }
    } else {
      // The layout has no fields
      setFields([])
      setSelectedFields([])
      setSelectedFieldsReset([])
      selectedFieldsRef.current = []
    }
  }, [layout])


  const getTypeQuestion = (option) => {


    let type = ''
    for (let i in questions) {
      const key = questions[i]


      if (key.question === option) {
        type = key.response.field_type
      }
    }

    const label = field_types.find(item => item.value == type)?.label
    return label
  }

  const getIsAdminQuestion = (option) => {


    let type = false
    for (let i in questions) {
      const key = questions[i]

      if (key.question === option) {
        type = key.isSuper
      }
    }

    console.log('isAdmin', option, type)

    return type
  }

  const capitalize = (str) => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }

    return ''
  }


  return (
    <Stack
      spacing={3}
      sx={{
        p: 0.25,
        height: 1,
      }}
    >
      <KanbanDetails
        field={
          selectedFields.find((item) => item?.order_id === selectedID.current) ||
          {}
        }
        openDetails={openDetails.value}
        onCloseDetails={() => {
          openDetails.onFalse()
          selectedID.current = null
        }}
        onDeleteDetails={async (data, option, id) => {

          const select = selectedFields.find((item) => item.order_id === data.order_id)


          openDetails.onFalse()
          selectedID.current = null

          let newValues = []
          for (let i in selectedFields) {
            const key = selectedFields[i]
            if (data.order_id !== key.order_id) {
              newValues.push(key)
            }
          }


          if (option === 'remove_question') {
            const result = await removeQuestion({
              question: select.question,
              landing_pages_id: landing_page_id,
              field_id: select?.response?.id
            })

            enqueueSnackbar('Delete Question Success', { variant: 'success' })

            resultQuestions()
          }



          setSelectedFields(newValues)
          onChange(newValues)
          setFields(newValues)


        }}
        onSubmit={onSaveSubmit}
        options={options}
        landing_page_id={landing_page_id || ''}
      />

      <Box>
        <Stack spacing={3} sx={{ pt: 2, mr: 2, pb: 2 }}>
          <Box width={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>

            <Button
              disabled={isSuper}
              color="primary"
              startIcon={<Iconify icon="mingcute:add-line" />}
              onClick={() => {
                // selectedID.current = item.id
                selectedID.current = null
                openDetails.onTrue()
              }}
            >
              New Question
            </Button>
          </Box>
        </Stack>
        <FormControl fullWidth>

          {console.log(questions)}

          <RHFAutocompleteAction
            disabled={isSuper}
            name="add_field"
            label=""
            helperText=""
            placeholder=""
            fullWidth
            multiple={true}
            multiline={true}
            freeSolo
            onChange={handleChangeFields}
            getOptionLabel={(option) => option}
            options={questions?.map(item => item.question) || []}
            value={selectedFields?.map(item => item.question) || []}
            renderInput={(params) => (
              <TextField {...params} label="Apply Existing Question From Catalog" placeholder="Apply Existing Question From Catalog" />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option} style={{ justifyContent: 'space-between' }}>

                <div>
                  {option} - <small style={{ color: 'gray', paddingLeft: 3 }}>{capitalize(getTypeQuestion(option))}</small>
                </div>
                {
                  getIsAdminQuestion(option) && <img src={icon('ic_landing')?.props?.src} />
                }

              </li>
            )}
            renderTags={(selected, getTagProps) =>
              selected.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option}
                  label={option}
                  size="small"
                  onDelete={() => deleteQuestion(index)}

                />
              ))
            }
          />

        </FormControl>
      </Box>



      {selectedFields.length > 0 ? (
        <Stack>
          <DragDropContext onDragEnd={(e) => onDragEnd(e, selectedFields)}>
            <Droppable droppableId="todos" type="TASK">
              {(droppableProvider) => (
                <Stack

                  ref={droppableProvider.innerRef}
                  {...droppableProvider.droppableProps}
                  spacing={2}
                  sx={{
                    py: 3,
                  }}
                >
                  {selectedFields?.map((item, index) => ({
                    question: item.question,
                    order_id: item.order_id,
                    response: item.response?.field_label,
                    order: item?.landing_pages_id?.sort((a, b) => console.log(a, b)) || (parseInt(index) + 1),
                    required: item.required
                  })).map((item, index) => (

                    <Draggable
                      isDragDisabled={isSuper}
                      index={index}
                      key={item.order_id}
                      draggableId={`item-${item.order_id}`}
                    >
                      {(draggableProvider, snapshot) => (
                        <Paper
                          disabled={isSuper}
                          ref={draggableProvider.innerRef}
                          {...draggableProvider.draggableProps}
                          {...draggableProvider.dragHandleProps}
                          onClick={() => {
                            if (isSuper) return
                            console.log(item)
                            selectedID.current = item.order_id
                            openDetails.onTrue()
                          }}
                          sx={{
                            width: 1,
                            borderRadius: 1.5,
                            overflow: 'hidden',
                            position: 'relative',
                            bgcolor: 'background.default',
                            boxShadow: theme.customShadows.z1,
                            '&:hover': {
                              boxShadow: theme.customShadows.z20,
                            },
                            ...(openDetails.value && {
                              boxShadow: theme.customShadows.z20,
                            }),
                            ...(snapshot.isDragging && {
                              boxShadow: theme.customShadows.z20,
                              ...bgBlur({
                                opacity: 0.48,
                                color: theme.palette.background.default,
                              }),
                            }),
                          }}
                        >
                          <Stack
                            direction="row"
                            sx={{
                              px: 2,
                              py: 2.5,
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Stack flexGrow={1} direction="column">
                              <Typography component="span" variant="title1">
                                {item.question} {getIsAdminQuestion(item.question) && <img style={{ width: '2.5%', position: 'absolute', top: 19, marginLeft: 10 }} src={icon('ic_landing')?.props?.src} />}
                              </Typography>
                              <Typography component="span" variant="subtitle2">
                                Response: <small>{item.response}</small>
                              </Typography>

                            </Stack>
                            <Stack
                              flexGrow={1}
                              direction="row"
                              spacing={4}
                              justifyContent="flex-end"
                              pt={1.5}
                            >
                              <Typography variant="subtitle2">
                                {item.required ? 'Required' : 'Optional'}
                              </Typography>
                              <Typography variant="subtitle2">
                                <Chip
                                  color="primary"
                                  label={index + 1}
                                  size="small"
                                  variant="soft"
                                />
                              </Typography>
                            </Stack>
                          </Stack>
                        </Paper>
                      )}
                    </Draggable>

                  ))}
                  {droppableProvider.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        </Stack>
      ) : (
        <Stack>
          <EmptyContent
            filled
            title="No Data"
            sx={{
              py: 10,
            }}
          />
        </Stack>
      )}
    </Stack>
  )
}

LayoutForm.propTypes = {
  section: PropTypes.string,
  user_level: PropTypes.string,
  onChange: PropTypes.func,
  saveChange: PropTypes.bool,
  data: PropTypes.array,
  landing_page_id: PropTypes.string,
  isSuper: PropTypes.bool
}
