import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// components
import { Fab, MenuItem, Popover, Stack, TextField, useTheme } from '@mui/material';
import Iconify from 'src/components/iconify';
import { fphoneNumber } from 'src/utils/format-phonenumber';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// firebase
import { DATABASE } from 'src/firebase';
import { ref, onValue } from "firebase/database";
// ----------------------------------------------------------------------
export default function PhoneNumberCalling({
    phoneNumber,
    callEnd,
    recording,
    mute,
    hold,
    isHold,
    isMute,
    onChangeAudio,
    isRecording,
    isCallActive,
    direction
}) {

    const theme = useTheme();
    const [isOpen, setOpen] = useState(null);
    const [audioOutputDevices, setAudioOutputDevices] = useState([]);
    const [seconds, setSeconds] = useState(0);

    const onCall = useBoolean(false);

    // Escucha cambios en la ruta 'twilioRealTimeEvents/'
    useEffect(() => {
        const callsRef = ref(DATABASE, 'twilioRealTimeEvents/');
        if(direction==='inbound')onCall.onTrue();
        const unsubscribe = onValue(callsRef, (snapshot) => {
            const data = snapshot.val();

            if (data && data[isCallActive.sid]) {
                onCall.onTrue();
            }
        });
        // Limpia la suscripción cuando el componente se desmonte
        return () => unsubscribe();
        // eslint-disable-next-line
    }, []);
    
    // Efecto para manejar el contador
    useEffect(() => {
        let timer;
        if (onCall.value) {
            timer = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds + 1);
            }, 1000);
        } else {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
        // eslint-disable-next-line
    }, [onCall]);



    const formatTime = () => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };
    const handleClose = useCallback((deviceId) => {
        onChangeAudio(deviceId)
        setOpen(null);
    }, [onChangeAudio]);

    const enumerateAudioOutputDevices = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const isAudioOutputDevices = devices.filter(device => device.kind === 'audiooutput');
            setAudioOutputDevices(isAudioOutputDevices);
            setOpen(true);
        } catch (error) {
            console.error('Failed to enumerate audio devices:', error);
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: theme.spacing(3),
        }}>
            <Typography color="primary" variant='body2' sx={{ marginBottom: theme.spacing(1) }}>{
                onCall.value ? "On Call" : "Calling..."
            }</Typography>
            {onCall.value && (
                <Typography
                    sx={{
                        fontSize: '1.5rem',
                        marginBottom: theme.spacing(0),
                    }}
                >
                    {formatTime()}
                </Typography>
            )}
            <Typography sx={{
                fontSize: '1.5rem',
                marginBottom: theme.spacing(0),
            }}>{fphoneNumber(phoneNumber)}</Typography>
            <Typography variant='subtitle2' sx={{ color: '#4ba4c0', marginBottom: theme.spacing(6) }}>Save To CRM</Typography>
            <Box width="100%" sx={{ px: 2, mb: 2, maxHeight: 100, overflowY: 'scroll', marginBottom: theme.spacing(2) }}>
                <TextField
                    value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                Quisque ornare ex velit, cursus placerat nibh commodo et. 
                Proin eu facilisis augue. Praesent purus sapien, aliquet 
                sit amet porttitor non, iaculis."
                    multiline variant='filled'
                    fullWidth label="Notes" />
            </Box>
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: theme.spacing(3),
                marginBottom: theme.spacing(1.5),
            }}>
                <Stack>
                    <Stack>
                        <Fab size='medium' color='gray.300' onClick={enumerateAudioOutputDevices}>
                            <Iconify icon="iconoir:sound-high-solid" width={20} height={20} />
                        </Fab>
                        <Popover
                            sx={{ top: -90 }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            id="simple-menu"
                            anchorEl={isOpen}
                            onClose={handleClose}
                            open={Boolean(isOpen)}>
                            {audioOutputDevices.map((device) => (
                                <MenuItem key={device.deviceId} onClick={() => handleClose(device.deviceId)}>
                                    {device.label || `Device ${device.deviceId}`}
                                </MenuItem>
                            ))}
                        </Popover>
                    </Stack>
                    <Typography variant='caption'>Audio</Typography>
                </Stack>
                <Stack
                    direction='column'
                    alignItems='center'
                    justifyContent='center'
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative',
                    }}
                >
                    <Fab size='medium' disabled={isHold} color={isMute ? 'default' : 'default'} onClick={mute}>
                        <Iconify icon="mdi:mute" color={isMute ? "red" : 'default'} width={20} height={20} />
                    </Fab>
                    <Typography variant='caption'>Mute</Typography>
                </Stack>
                <Stack
                    direction='column'
                    alignItems='center'
                    justifyContent='center'
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative',
                    }}
                >
                    <Fab size='medium' color='gray.300' onClick={callEnd}>
                        <Iconify icon="mdi:user-add" width={20} height={20} />
                    </Fab>
                    <Typography variant='caption'>Add</Typography>
                </Stack>
                <Stack
                    direction='column'
                    alignItems='center'
                    justifyContent='center'
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative',
                    }}
                >
                    <Fab size='medium' color={isHold ? 'default' : 'default'} onClick={hold}>
                        <Iconify color={isHold ? "red" : 'default'} icon="material-symbols:pause" width={20} height={20} />
                    </Fab>
                    <Typography variant='caption'>Hold</Typography>
                </Stack>
                <Stack
                    direction='column'
                    alignItems='center'
                    justifyContent='center'
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative',
                    }}
                >
                    <Fab size='medium' disabled={isHold} color='gray.300' onClick={recording}>
                        <Iconify icon="ph:record-fill" color={isRecording && !isHold ? 'red' : 'default'} width={20} height={20} />
                    </Fab>
                    <Typography variant='caption'>Record</Typography>
                </Stack>
                <Stack
                    direction='column'
                    alignItems='center'
                    justifyContent='center'
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative',
                    }}
                >
                    <Fab size='medium' color='gray.300' onClick={callEnd}>
                        <Iconify icon="mingcute:transfer-fill" width={20} height={20} />
                    </Fab>
                    <Typography variant='caption'>Transfer</Typography>
                </Stack>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: theme.spacing(1.5),
            }}>
                <Fab size='medium' onClick={callEnd} color="error" sx={{ mb: 0, mt: 1 }}>
                    <Iconify icon="solar:phone-bold" />
                </Fab>
            </Box>
        </Box>
    );
}

PhoneNumberCalling.propTypes = {
    phoneNumber: PropTypes.string,
    callEnd: PropTypes.func,
    mute: PropTypes.func,
    hold: PropTypes.func,
    isHold: PropTypes.bool,
    isMute: PropTypes.bool,
    isRecording: PropTypes.bool,
    onChangeAudio: PropTypes.func,
    recording: PropTypes.func,
    isCallActive: PropTypes.string,
    direction: PropTypes.string
};